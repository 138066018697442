import React from 'react'
import Layout from '../../components/Layout'
import Hero from '../../components/Hero'
import SEO from "../../components/seo"
import CTA from "../../components/cta"

export default () => (
  <Layout>

    <SEO title="Solution for tour operators, suppliers and guides"
        description="The Getlocal solution works for tour operators, suppliers and guides that want to sell their services online"
        pathname="/pricing/tour-operator-supplier/"
    />
        
    <Hero 
         headerText="Tour operators" 
         subHeaderText="Solution for tour operators, suppliers and guides"
            /> 
    
    <section className="section column is-10 is-offset-1 content-pages">
      <div className="container">
        <div className="content center intro">
            <h2>Used by travel companies of all kinds</h2>
            
            <p>No matter what type of travel company you manage the Getlocal solution can help you improve your online sales and marketing.</p>
            <CTA />
        </div>

       
        
      </div>
    </section>
    <section className="section content-pages">
    <div className="content columns">
        <div className="column is-6">
            <h2>What types of companies use Getlocal?</h2>
            <ul>
                <li>Private tour guides</li>
                <li>Day tour operators</li>
                <li>Safari's and Pub crawls</li>
                <li>Whale watching and boat tours</li>
                <li>Airport transfers</li>
                <li>And many more</li>
            </ul>
        </div>

        <div className="column is-6">
            <h2>Problems we help our customers solve</h2>
            <ul>
                <li>Building a good website is expensive</li>
                <li>Managing a website is complicated</li>
                <li>Marketing is hard and time consuming</li>
                <li>Taking payments online is not available</li>
                <li>Customers don't convert and book online</li>
                <li>Website is slow and hard to use</li>
            </ul>
        </div>
        </div>
    </section>

  </Layout>
)
